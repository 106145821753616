import { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import translate from 'i18n-translations/translate.jsx';
import {
	getAiSettingsConfigurations,
	getConfigurationValue,
	getConfigurationVariant,
	updateConfigsList,
} from 'infrastructure/helpers/commonHelpers.js';
import {
	AiAlertActivityType,
	CallTypes,
	DeviceStatusMessages,
	ParticipantRemoveReason,
	ParticipantState,
} from 'constants/enums.js';
import Eye from 'icons/Monitoring/Eye.jsx';
import {
	AiSetting,
	SettingsCategory,
	UserSettingTypes,
	configurableAISettings,
	configurableMonitoringMenu,
	MonitoringSettings,
	CallWorkflowType,
} from 'constants/configurationEnums.js';
import { getMonitoringSettingOptions, getRoomSettings } from 'api/adminConfigurations.js';
import { getUserPreferences } from 'api/users.js';
import classNames from 'classnames';
import Alert from 'components/Alert.jsx';
import ProfilePicture from 'components/ProfilePicture.jsx';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';
import SocketEvents from 'constants/socket-events.js';
import { actionCreators as aiSettingsActionCreators } from 'state/aiSettings/actions.js';
import Tooltip from 'calls/components/Tooltip.jsx';
import ToastMessage from 'components/ToastMessage.jsx';
import Button from 'components/Button.jsx';
import { getUserRole } from 'infrastructure/auth.js';
import PrecautionsInCareNotifications from 'components/Monitoring/PrecautionsInCareNotifications.jsx';
import { aiTypesToShow, isSettingEnabled } from 'infrastructure/helpers/aiHelper.js';

const AlertFeed = ({
	className,
	feed,
	onCloseClick,
	onManageAlertClick,
	intl,
	onProviderIntervening,
	isFromMonitoring = false,
	onToggleAlert,
	toggleToMonitoring,
	onDragStart,
	onDragOver,
	onDragEnd,
	reAddFeed,
	closeDeviceLockedModal,
	callManager,
	privacyModeError = false,
	closePrivacyModeError,
	setDiscontinueMonitoringReasons,
	monitoringRoundingSettings = [],
	setMonitoringRoundingSettings = (settings = null) => settings,
	stopVoiceOver,
}) => {
	const aiSettingList = useSelector(state => state.aiSettingsList.aiSettings);
	const isDarkMode = useSelector(state => state.user.darkMode);
	const [precautions, setPrecautions] = useState([]);
	const [error, setError] = useState('');
	const [adminAiSettingsConfigurations, setAdminAiConfigurations] = useState(configurableAISettings());
	const [nameOfNurseAlsoMonitoring, setNameOfNurseAlsoMonitoring] = useState('');
	const [isRetryInviteDisabled, setIsRetryInviteDisabled] = useState(false);
	const [adminConfigurations, setAdminConfigurations] = useState(configurableMonitoringMenu(getUserRole()));
	const socket = useContext(SocketContext);
	const dispatch = useDispatch();
	const prevDeviceStateRef = useRef(null);
	const maxSimultaneousMonitoringFeedsReachedRef = useRef(false);
	const retryInviteTimeoutRef = useRef(null);
	const retryInviteInterval = 10000;

	const retryInvite = () => {
		setIsRetryInviteDisabled(true);
		retryInviteTimeoutRef.current = setTimeout(() => {
			setIsRetryInviteDisabled(false);
		}, retryInviteInterval);
		reAddFeed(feed.deviceId);
	};

	const isEhrField = () =>
		getConfigurationValue(adminConfigurations[MonitoringSettings.Precautions]) &&
		getConfigurationVariant(adminConfigurations[MonitoringSettings.Precautions])?.value === 'ehr-patient-data-load';

	useEffect(() => {
		const callStateChanged = data => {
			if (data.deviceId === feed.deviceId) {
				const activeDeviceMonitoringFeeds = data.activeConferences?.filter(
					feed => feed.callType === CallTypes.MONITORING
				)?.length;
				const prevIsDeviceOnACall = prevDeviceStateRef.current?.activeConferences?.some(
					feed =>
						feed.callType === CallTypes.SECURITY_CAM || feed.callType === CallTypes.VIDEO || feed.callType === CallTypes.AUDIO
				);
				const isDeviceOnACall = data.activeConferences.some(
					feed =>
						feed.callType === CallTypes.SECURITY_CAM || feed.callType === CallTypes.VIDEO || feed.callType === CallTypes.AUDIO
				);

				if (feed.showDeviceControlsLockedModal && !isDeviceOnACall) {
					closeDeviceLockedModal();
				}
				const MaxSimultaneousMonitoringFeeds = 2;
				if (
					prevIsDeviceOnACall &&
					!isDeviceOnACall &&
					activeDeviceMonitoringFeeds < MaxSimultaneousMonitoringFeeds &&
					(feed.reason === ParticipantRemoveReason.DISCONNECTED_BY_CALL || feed.status === ParticipantState.BUSY.type)
				) {
					reAddFeed(feed.deviceId);
				}
				prevDeviceStateRef.current = data;
				const monitoringConnections = data.activeConferences?.filter(feed => feed.callType === CallTypes.MONITORING);

				maxSimultaneousMonitoringFeedsReachedRef.current = activeDeviceMonitoringFeeds >= MaxSimultaneousMonitoringFeeds;
				setNameOfNurseAlsoMonitoring(monitoringConnections.length > 0 ? monitoringConnections?.at(0)?.name : '');
			}
		};

		callManager?.on('call-state-changed', callStateChanged);

		return () => {
			callManager?.off('call-state-changed', callStateChanged);
		};
	}, [callManager, feed]);

	useEffect(() => {
		const fetchDiscontinueMonitoringReasons = async () => {
			const response = await getMonitoringSettingOptions(feed?.healthSystemId, MonitoringSettings.DiscontinueMonitoring);
			if (response.error) {
				setError(response.error.message);
				return;
			}
			setDiscontinueMonitoringReasons(response);
		};
		if (isFromMonitoring) {
			fetchDiscontinueMonitoringReasons();
		}
	}, [feed?.healthSystemId]);

	useEffect(() => {
		const fetchRoomSettings = async () => {
			const [myRoomSettings, adminAiSettings, aiRoomSettings, adminRoomSettings] = await Promise.all([
				getUserPreferences(UserSettingTypes.Monitoring, feed.roomId),
				getRoomSettings(feed.roomId, SettingsCategory.AI_SETTINGS),
				getUserPreferences(UserSettingTypes.AiSettings, feed.roomId),
				getRoomSettings(feed.roomId, SettingsCategory.MONITORING),
			]);
			const responseError = myRoomSettings.error || adminAiSettings.error || aiRoomSettings.error || adminRoomSettings.error;
			if (responseError) {
				setError(responseError.message);
				return;
			}
			const monitoringAiSettings = getAiSettingsConfigurations(adminAiSettings, aiRoomSettings, CallWorkflowType.MONITORING);
			const roundingAiSettings = getAiSettingsConfigurations(adminAiSettings, aiRoomSettings, CallWorkflowType.ROUNDING);
			const adminResponse = updateConfigsList(adminRoomSettings, myRoomSettings);
			const settingsError = monitoringAiSettings.error || roundingAiSettings.error;
			if (settingsError) {
				setError(settingsError);
			} else {
				setAdminAiConfigurations({ ...monitoringAiSettings.configs, ...roundingAiSettings.configs });
			}
			setAdminConfigurations(adminResponse.configs);
		};
		fetchRoomSettings();
	}, [feed.roomId]);

	useEffect(() => {
		if (feed.intervention?.value) {
			setTimeout(() => {
				onProviderIntervening(feed.deviceId);
			}, 20000);
		}
	}, [feed.intervention, feed.deviceId, onProviderIntervening]);

	const checkEnabledSettings = settingTypeId => {
		const aiSettings = monitoringRoundingSettings.length > 0 ? monitoringRoundingSettings : aiSettingList;
		return isSettingEnabled(aiSettings.find(({ deviceId }) => deviceId === feed.deviceId)?.settings, settingTypeId);
	};

	const filterAiTypesByCustomConditions = ({ adminAiSettingsConfigurations, isDarkMode, intl }) => {
		return aiTypesToShow({ adminAiSettingsConfigurations, isDarkMode, intl, showFallPrevention: false }).map(item => ({
			...item,
			show: item.show && checkEnabledSettings(item.aiTypeId),
		}));
	};

	useEffect(() => {
		const setAiSettingsAction = ai => dispatch(aiSettingsActionCreators.setPatientAiSettings(ai));

		const onPatientAiSettingsUpdated = ({ patientAiSettings, deviceId, workflowType }) => {
			if (feed.deviceId !== deviceId || (workflowType === CallWorkflowType.ROUNDING && monitoringRoundingSettings.length === 0)) {
				return;
			}
			if (monitoringRoundingSettings.length === 0) {
				setAiSettingsAction({ deviceId, settings: patientAiSettings });
			} else {
				setMonitoringRoundingSettings(prevState => {
					const existingDevice = prevState.find(item => item.deviceId === deviceId);
					if (existingDevice) {
						return prevState.map(item => (item.deviceId === deviceId ? { ...item, settings: patientAiSettings } : item));
					}
					return [...prevState, { deviceId, settings: patientAiSettings }];
				});
			}
		};

		const onAiAlertActivity = data => {
			if (!isFromMonitoring) {
				return;
			}
			if (
				[AiAlertActivityType.ACKNOWLEDGED, AiAlertActivityType.AI_FAKE_ALERT, AiAlertActivityType.AI_FORWARD_TO_NURSES].includes(
					data.aiAlertActivityTypeId
				) &&
				data.deviceId === feed.deviceId &&
				data?.workflowTypes.includes(CallWorkflowType.MONITORING)
			) {
				stopVoiceOver(feed);
			}
		};

		socket.on(SocketEvents.Alerts.PATIENT_AI_SETTINGS_UPDATED, onPatientAiSettingsUpdated);
		socket.on(SocketEvents.Alerts.NEW_AI_ALERT_ACTIVITY, onAiAlertActivity);

		return () => {
			socket.off(SocketEvents.Alerts.PATIENT_AI_SETTINGS_UPDATED, onPatientAiSettingsUpdated);
			socket.off(SocketEvents.Alerts.NEW_AI_ALERT_ACTIVITY, onAiAlertActivity);
		};
	}, [feed.deviceId, dispatch, socket]);

	const getDeviceStatus = () => {
		const getRetryButton = () => {
			return (
				<>
					<br />
					<Button disabled={isRetryInviteDisabled} onClick={retryInvite} className='re-add-feed' text={translate('retry')} />
				</>
			);
		};

		const getNursesInMonitoringNames = () => {
			let names = '';

			if (feed.nursesInConference?.length > 1) {
				names = feed.nursesInConference.map(obj => obj.name).join(` ${intl.formatMessage({ id: 'and' })} `);
			}

			return names;
		};

		if (feed.status === ParticipantState.CONNECTED.type && feed.isCameraPrivacyOn) {
			return DeviceStatusMessages.privacyModeOn;
		}
		switch (feed.status) {
			case ParticipantState.BUSY.type:
				if (maxSimultaneousMonitoringFeedsReachedRef.current) {
					return translate('maximumNumberOfMonitoringSessions', { value: getNursesInMonitoringNames() });
				}

				if (feed.onPatientBusyNurse?.name) {
					return `${DeviceStatusMessages.deviceOnCallWithNurse} ${feed.onPatientBusyNurse.name}.`;
				}
				return DeviceStatusMessages.deviceOnCall;
			case ParticipantState.OFFLINE.type:
				return DeviceStatusMessages.deviceOffline;
			case ParticipantState.NOT_ANSWERING.type:
				return (
					<>
						{DeviceStatusMessages.notAnswering}
						{getRetryButton()}
					</>
				);
			case ParticipantState.DISCONNECTED.type:
			case ParticipantState.LEFT_CALL.type:
				if (feed.reason === ParticipantRemoveReason.DISCONNECTED_BY_CALL) {
					return <>{DeviceStatusMessages.disconnectedByCall}</>;
				}
				if (feed.reason === ParticipantRemoveReason.FAILED_TO_GET_INFO) {
					return (
						<>
							{DeviceStatusMessages.failedToGetInfo} {getRetryButton()}
						</>
					);
				}
				return (
					<>
						{DeviceStatusMessages.leftCall} {getRetryButton()}
					</>
				);
			case ParticipantState.RECONNECTING.type:
				return DeviceStatusMessages.reconnecting;
			case ParticipantState.REMOVED.type:
				return DeviceStatusMessages.removed;
			case ParticipantState.INVITE_DENIED.type:
				return DeviceStatusMessages.inviteDenied;
			case ParticipantState.FAILED_TO_GET_INFO.type:
				return (
					<>
						{DeviceStatusMessages.failedToGetInfo}
						<br />
						<Button onClick={() => reAddFeed(feed.deviceId)} className='re-add-feed' text={translate('retry')} />
					</>
				);
			default:
				return DeviceStatusMessages.initiatingMonitoring;
		}
	};

	const filteredPrecautions = precautions?.filter(item => item.active);
	const shownAiTypes = filterAiTypesByCustomConditions({ adminAiSettingsConfigurations, isDarkMode, intl }).filter(
		item => item.show
	);
	const isAmbientConnected = feed.status === ParticipantState.CONNECTED.type;

	return (
		<>
			<div
				className={classNames('alert-feed flex column-direction full-width full-height', className || '', {
					'high-risk-alert': feed.warning?.isAiAlert || feed.isStatAlarmActive,
					'high-risk-stat-alarm-alert': feed.isStatAlarmActive,
					'monitoring-alert-feed': isFromMonitoring,
				})}
				onClick={isFromMonitoring && isAmbientConnected ? onToggleAlert : null}
				draggable={isFromMonitoring}
				onDragStart={isFromMonitoring ? onDragStart : null}
				onDragOver={isFromMonitoring ? onDragOver : null}
				onDragEnd={isFromMonitoring ? onDragEnd : null}
				style={{
					cursor: isFromMonitoring ? 'move' : 'initial',
				}}>
				<div className='alert-feed-header'>
					<span className='alert-feed-room-name flex flex-align-center'>
						{isFromMonitoring && nameOfNurseAlsoMonitoring && (
							<Tooltip
								text={`${intl.formatMessage({ id: 'beingMonitoredBy' })} ${nameOfNurseAlsoMonitoring}`}
								isPortal={true}
								position='right'>
								<div className='monitoring-nurses-badge'>
									<span className='material-icons'>
										<Eye />
									</span>
								</div>
							</Tooltip>
						)}
						{feed.roomName}
					</span>
					<span className='flex flex-align-center'>
						{isFromMonitoring && (
							<>
								{isAmbientConnected && (
									<>
										<span className='right-s font-10 monitor-patient-title'>Video</span>
										<div className='flex flex-align-center'>
											<label className='switch-label auto-width right-s'>
												<div className='toggle-switch toggle-blue'>
													<input type='' checked={false} onClick={toggleToMonitoring} />
													<span className='toggle-body'>
														<span className='on-text'>{translate('on')}</span>
														<span className='off-text'>{translate('off')}</span>
													</span>
												</div>
											</label>
										</div>
									</>
								)}

								<div
									className='alert-feed-icon-wrapper'
									onClick={event => {
										if (event) {
											event.stopPropagation();
										}
										onCloseClick(getConfigurationValue(adminConfigurations[MonitoringSettings.DiscontinueMonitoring]));
									}}>
									<i className='material-icons-outlined alert-feed-icon'>close</i>
								</div>
							</>
						)}
					</span>
				</div>
				<div className='alert-feed-content-wrapper flex column-direction flex-align-center position-relative overflow-hidden'>
					{(!isFromMonitoring || isAmbientConnected) && (
						<>
							{!feed.isDefaultOwner && (
								<>
									{feed.warning?.isAiAlert && !feed.isStatAlarmActive && (
										<>
											<span className='flex flex-justify-center flex-align-center flex-justify-center'>{feed.warning.icon}</span>
											<span>{feed.warning.text}</span>
											<span
												className='manage-alert-button'
												onClick={isFromMonitoring ? toggleToMonitoring : () => onManageAlertClick(feed)}>
												{translate(isFromMonitoring ? 'turnVideoOn' : 'manageAlert')}
											</span>
										</>
									)}
									{feed.isStatAlarmActive && <span>{translate('statAlarmStarted')}</span>}
									{!feed.warning?.isAiAlert && !feed.isStatAlarmActive && (
										<div className='flex full-height position-relative gap-s'>
											<PrecautionsInCareNotifications
												feed={feed}
												setPrecautions={setPrecautions}
												filteredPrecautions={filteredPrecautions}
												isEhrField={isEhrField()}
											/>
											<div
												className={classNames('flex column-direction full-height full-width text-align-center gap-s', {
													'has-precautions': filteredPrecautions.length > 0,
													'flex-justify-center': shownAiTypes.length === 0,
													'no-padding-left': filteredPrecautions.length === 0,
												})}>
												{!getConfigurationValue(adminAiSettingsConfigurations[AiSetting.SILENT_MODE]) &&
													filterAiTypesByCustomConditions({ adminAiSettingsConfigurations, isDarkMode, intl }).map(
														item =>
															item.show && (
																<div className='alert-feed-active-case' key={item.aiTypeId}>
																	{item.img} <span className='left-s'>{item.label}</span>
																</div>
															)
													)}
												{shownAiTypes.length === 0 && (
													<span className='unoccupied-room'>{translate('ambientMonitoringNotActive')}</span>
												)}
											</div>
										</div>
									)}
								</>
							)}
							{feed.isDefaultOwner && <span className='unoccupied-room'>{translate('unoccupied')}</span>}
							{feed.intervention?.value && (
								<div className='flex flex-justify-center flex-align-center gap-m position-absolute full-width nurse-intervening-container'>
									<ProfilePicture
										fullName={feed.intervention?.providerFullName}
										profilePicture={feed.intervention?.providerProfilePicture}
										className='provider-intervening-img-wrapper'
									/>
									<span className='flex column-direction left-align-items'>
										<span>{feed.intervention?.providerFullName}</span>
										<span>{translate('isIntervening')}</span>
									</span>
								</div>
							)}
						</>
					)}
					{isFromMonitoring && !isAmbientConnected && <span className='unoccupied-room'>{getDeviceStatus()}</span>}
					<ToastMessage
						showToast={privacyModeError}
						onClose={closePrivacyModeError}
						className='feed-toast-message position-absolute'>
						<span>{translate('cannotEnablePrivacyMode')}</span>
					</ToastMessage>
				</div>
			</div>
			<Alert display={error} fixed hideCloseButton message={error} variant='dark' />
		</>
	);
};

export default AlertFeed;
