import React, { useState, useEffect, useRef, useContext, useMemo } from 'react';
import reactStringReplace from 'react-string-replace';
import { v4 } from 'uuid';
import { useIntl } from 'react-intl';
import _ from 'lodash';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { buildProfilePic, onErrorDefaultSrc } from 'infrastructure/helpers/thumbnailHelper.js';
import { getUserInfo, getUserRole } from 'infrastructure/auth.js';
import { getFormattedDate, convertUTCDateToLocalDateOrTime } from 'infrastructure/helpers/dateHelper.js';
import {
	isPictureAttachment,
	isDocumentAttachment,
	isVideoAttachment,
	removeSpecialCharacters,
	decodeHtml,
	areSameObject,
	setConversationMembersInfo,
	getConversationMembersInfo,
} from 'infrastructure/helpers/commonHelpers.js';
import {
	getHistoryByConversationId,
	insertMessage,
	uploadAttachment,
	updateReadStatus,
	getConversationMembers,
	getChannelMembers,
	getMembersByObjectType,
} from 'api/messenger.js';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';
import SocketEvents from 'constants/socket-events.js';
import { AttachmentType, ConversationItemsType } from 'constants/enums.js';
import Loader from 'components/Loader.jsx';
import Alert from 'components/Alert.jsx';
import ProfilePicture from 'components/ProfilePicture.jsx';
import translate from 'i18n-translations/translate.jsx';
import EmptyState from 'components/EmptyState.jsx';
import Grid from 'components/Grid.jsx';
import ChatRightSide from 'containers/Chat/ChatRightSide.jsx';
import ChatAdaptiveCard from 'components/ChatAdaptiveCard.jsx';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import MediaViewer from 'views/Partials/MediaViewer.jsx';
import { GeneralAndMenuSettings } from 'constants/configurationEnums.js';

const Conversation = props => {
	const linkPattern =
		/(https?:([^.\s]{2,})\.([a-zA-Z0-9-_]+\.)+[a-zA-Z]{2,}(:[^\s\D]+[a-zA-Z0-9-_.~!#$%&'"()*+,/:;=?@[\]\\^_`{|}~]*|[%][a-zA-Z0-9-_.~!#$%&'"()*+,/:;=?@[\]\\^_`{|}~]*)?|https?:([a-zA-Z0-9-_]+\.)+[a-zA-Z]{2,}(:[^\s\D]+[a-zA-Z0-9-_.~!#$%&'"()*+,/:;=?@[\]\\^_`{|}~]*|[%][a-zA-Z0-9-_.~!#$%&'"()*+,/:;=?@[\]\\^_`{|}~]*)?|www\.([a-zA-Z0-9-_]+\.)+[a-zA-Z]{2,}(:[^\s\D]+[a-zA-Z0-9-_.~!#$%&'"()*+,/:;=?@[\]\\^_`{|}~]*|[%][a-zA-Z0-9-_.~!#$%&'"()*+,/:;=?@[\]\\^_`{|}~]*)?)|\b(?:https?:)?(?:(?:2(?:[0-4][0-9]|5[0-5])|[0-1]?[0-9]?[0-9])\.){3}(?:(?:2([0-4][0-9]|5[0-5])|[0-1]?[0-9]?[0-9]))(?::\d*)?(?:[%][a-zA-Z0-9-_.~!#$%&'"()*+,/:;=?@[\]\\^_`{|}~]*)?\b/;

	const linksRegex = new RegExp(linkPattern, 'gim');

	const userInfo = getUserInfo();

	const writeMessageElement = useRef(null);

	const lastLoadedElement = useRef(null);

	const uploadFileElement = useRef(null);

	const uploadPhotoElement = useRef(null);

	const patientChatElement = useRef(null);

	const maxCharacterLength = 10000;

	const monitoringIconLink = `${healthCareCdnUrl}monitoring/`;

	const socket = useContext(SocketContext);

	const intl = useIntl();

	const [typingObject, setTypingObject] = useState(null);
	const [hasMessages, setHasMessages] = useState(false);
	const [conversationItems, setConversationItems] = useState([]);
	const [conversationGroups, setConversationGroups] = useState([]);
	const [updateReadStatusError, setUpdateReadStatusError] = useState(null);
	const [isSendingMessage, setIsSendingMessage] = useState(false);
	const [messageContent, setMessageContent] = useState('');
	const [showUploadPercentage, setShowUploadPercentage] = useState(false);
	const [isConversationLoading, setIsConversationLoading] = useState(true);
	const [isLoadingConversationItems, setIsLoadingConversationItems] = useState(false);
	const [reachedEnd, setReachedEnd] = useState(false);
	const [isDragingFile, setIsDragingFile] = useState(false);
	const [fileUploadPercentage, setFileUploadPercentage] = useState(100);
	const [error, setError] = useState(null);
	const [fetchConversationError, setFetchConversationError] = useState(null);
	const [conversationMembers, setConversationMembers] = useState([]);
	const [selectedImage, setSelectedImage] = useState(null);
	const configurations = useSelector(state => state.configurations);

	useEffect(() => {
		if (patientChatElement.current) {
			patientChatElement.current.addEventListener('dragenter', onEnter, false);
			patientChatElement.current.addEventListener('dragleave', onLeave, false);
			patientChatElement.current.addEventListener('dragover', onDragOverFile, false);
			patientChatElement.current.addEventListener('drop', onDropFile, false);
		}
		return () => {
			if (patientChatElement.current) {
				patientChatElement.current.removeEventListener('dragenter', onEnter, false);
				patientChatElement.current.removeEventListener('dragleave', onLeave, false);
				patientChatElement.current.removeEventListener('dragover', onDragOverFile, false);
				patientChatElement.current.removeEventListener('drop', onDropFile, false);
			}
		};
	}, []);

	useEffect(() => {
		const handleMessageDeleted = async data => {
			if (data.typeId === ConversationItemsType.UPCOMING_APPOINTMENT) {
				return;
			}
			if (props.conversationId === data.conversationId) {
				const convoItems = _.cloneDeep(conversationItems);
				const foundIndex = convoItems.findIndex(item => item.id === data.messageId);
				if (foundIndex !== -1) {
					convoItems.splice(foundIndex, 1);
				}
				await getExMembersData(convoItems);
				setConversationItems(convoItems);
			}
		};

		const handleNewEventMessage = async data => {
			if (data.typeId === ConversationItemsType.UPCOMING_APPOINTMENT) {
				return;
			}
			if (props.conversationId === data.conversationId) {
				receiveEventMessage(data.event);
			}
		};

		const handleNewMessage = data => {
			if (props.conversationId === data.conversationId) {
				receiveMessage(data.message);
			}
		};
		getConversation();
		socket.on(SocketEvents.Conversation.ON_NEW_MESSAGE, handleNewMessage);
		socket.on(SocketEvents.Conversation.ON_SET_TYPING, handleOnTyping);
		socket.on(SocketEvents.Conversation.ON_MESSAGE_DELETED, handleMessageDeleted);
		socket.on(SocketEvents.Conversation.ON_NEW_EVENT, handleNewEventMessage);
		return () => {
			socket.off(SocketEvents.Conversation.ON_NEW_MESSAGE, handleNewMessage);
			socket.off(SocketEvents.Conversation.ON_SET_TYPING, handleOnTyping);
			socket.off(SocketEvents.Conversation.ON_MESSAGE_DELETED, handleMessageDeleted);
			socket.off(SocketEvents.Conversation.ON_NEW_EVENT, handleNewEventMessage);
		};
	}, [props.conversationId]);

	useEffect(() => {
		setConversationGroups(groupConversationItems(conversationItems));
	}, [conversationItems]);

	const onEnter = event => {
		event.preventDefault();
		setIsDragingFile(true);
	};

	const onLeave = event => {
		event.preventDefault();
		setIsDragingFile(false);
		writeMessageElement.current.scrollIntoView({ behavior: 'instant', block: 'nearest', inline: 'start' });
	};

	const onDragOverFile = event => {
		event.preventDefault();
	};

	const onDropFile = event => {
		event.preventDefault();
		onFileUploadHandler(event);
		setIsDragingFile(false);
		writeMessageElement.current.scrollIntoView({ behavior: 'instant', block: 'nearest', inline: 'start' });
	};

	const getSendersIds = convoItems => Array.from(new Set(convoItems.map(item => item.sender?.objectId || item.owner?.objectId)));

	const getConversation = async () => {
		setFetchConversationError(null);
		if (!props.conversationId) {
			setIsConversationLoading(false);
			return;
		}
		setIsConversationLoading(true);
		setReachedEnd(false);
		const res = await getHistoryByConversationId(props.conversationId, null, 40);
		if (res.error) {
			setIsConversationLoading(false);
			setFetchConversationError(res.error.message);
			return;
		}
		let members = [];
		if (props.selectedTeamId) {
			const response = await getChannelMembers(props.selectedTeamId, props.selectedChannelId);
			if (!response.error) {
				members = response.channelMembers.map(member => ({ ...member, objectId: member.userId.toString(), objectTypeId: 0 }));
			}
		} else {
			const response = await getConversationMembers(props.conversationId);
			if (!response.error) {
				members = response.members;
			}
		}

		setConversationMembers(members);
		const membersInfo = getConversationMembersInfo(props.conversationId);
		if (membersInfo.length === 0) {
			const objects = members.map(member => ({
				objectId: member.objectId,
				objectTypeId: member.objectTypeId,
			}));
			const response = await getMembersByObjectType(objects);
			if (response.error) {
				setError(response.error.message);
			} else {
				const users = response.objects.map(item => ({
					firstName: `${item.displayName.substring(0, item.displayName.indexOf(' '))}`,
					lastName: `${item.displayName.substring(item.displayName.indexOf(' ') + 1, item.displayName.length)}`,
					objectId: +item.objectId,
					profilePicture: item.pictureThumbnail,
					objectTypeId: item.objectTypeId,
				}));
				setConversationMembersInfo(users, props.conversationId);
			}
		}

		try {
			await updateReadStatus(props.conversationId);
		} catch (ex) {
			setUpdateReadStatusError(`${intl.formatMessage({ id: 'errorOccurred' })} ${ex.message}`);
		}
		const events = res.events || [];
		const messages = res.messages || [];
		const convoItems = events.concat(messages);
		const promiseArr = convoItems.map(conversationItem => processImgHeight(conversationItem));
		const conversationItemsResolved = await Promise.all(promiseArr);
		await getExMembersData(conversationItemsResolved);
		setConversationItems(conversationItemsResolved.filter(item => item.typeId !== ConversationItemsType.UPCOMING_APPOINTMENT));
		setHasMessages(conversationItemsResolved.length > 0);
		setIsConversationLoading(false);
		if (writeMessageElement.current) {
			writeMessageElement.current.scrollIntoView({ behavior: 'instant', block: 'nearest', inline: 'start' });
		}
	};

	const getExMembersData = async convoItems => {
		if (convoItems.length === 0) {
			return [];
		}
		let members = getConversationMembersInfo(props.conversationId);
		const missingSenders = [];
		getSendersIds(convoItems).forEach(id => {
			const foundMember = members.find(item => item.objectId === +id);
			if (!foundMember) {
				missingSenders.push({ objectId: id, objectTypeId: 0 });
			}
		});
		if (missingSenders.length === 0) {
			return [];
		}
		const response = await getMembersByObjectType(missingSenders);
		if (response.error) {
			setError(response.error.message);
			return members;
		}
		const users = response.objects.map(item => ({
			firstName: `${item.displayName.substring(0, item.displayName.indexOf(' '))}`,
			lastName: `${item.displayName.substring(item.displayName.indexOf(' ') + 1, item.displayName.length)}`,
			objectId: +item.objectId,
			profilePicture: item.pictureThumbnail,
			objectTypeId: item.objectTypeId,
		}));
		members = [...members, ...users];
		return new Promise(resolve => {
			setConversationMembersInfo(members, props.conversationId);
			resolve(members);
		});
	};

	const handleOnTyping = data => {
		if (props.conversationId !== data.conversationId) {
			return;
		}
		setTypingObject(data.isTyping ? data : null);
	};

	const processImgHeight = conversationItem => {
		return new Promise((resolve, reject) => {
			const conversation = { ...conversationItem };
			if (
				!conversation.attachments ||
				!conversation.attachments.length ||
				isDocumentAttachment(conversation.attachments[0].typeId)
			) {
				resolve(conversation);
			}

			if (conversation.attachments && conversation.attachments.length) {
				if (conversationItem.typeId === AttachmentType.PICTURE) {
					const img = new Image();
					img.onload = () => {
						conversation.attachments[0].height = img.height;
						resolve(conversation);
					};
					img.onerror = reject;
					const { thumbnailUrl } = conversationItem.attachments[0];
					img.src = thumbnailUrl;
				} else {
					resolve(conversation);
				}
			}
		});
	};

	const receiveMessage = async message => {
		if (message.typeId === ConversationItemsType.UPCOMING_APPOINTMENT) {
			return;
		}
		const resolvedMessage = await processImgHeight(message);
		await getExMembersData([...conversationItems, resolvedMessage]);
		setConversationItems(prevState => [...prevState, resolvedMessage]);
		setHasMessages(true);
		scrollToBottom();
	};

	const receiveEventMessage = async event => {
		await getExMembersData([...conversationItems, event]);
		setConversationItems(prevState => [...prevState, event]);
		setHasMessages(true);
		scrollToBottom();
	};

	const handleScroll = async () => {
		if (
			!isConversationLoading &&
			!isLoadingConversationItems &&
			patientChatElement.current.scrollTop === 0 &&
			!reachedEnd &&
			!isDragingFile
		) {
			setIsLoadingConversationItems(true);
			const res = await getHistoryByConversationId(props.conversationId, getLatestDate(), 40);
			if (res.error) {
				setError(res.error.message);
				return;
			}
			const events = res.events.filter(item => item.typeId !== ConversationItemsType.UPCOMING_APPOINTMENT) || [];
			const messages = res.messages || [];
			await getExMembersData(messages.concat(events, conversationItems));
			setConversationItems(prevState => messages.concat(events, prevState));
			setIsLoadingConversationItems(false);
			setReachedEnd(events.concat(events).length === 0);
			if (conversationItems.length > 0 && lastLoadedElement.current) {
				lastLoadedElement.current.scrollIntoView({ behavior: 'instant', block: 'nearest', inline: 'start' });
			}
		}
	};

	const sendMessage = async attachments => {
		setIsSendingMessage(true);
		const content = attachments ? null : messageContent;
		const dateCreated = new Date().getTime();
		const clientMessageId = `${props.conversationId}_${userInfo.userId}_${dateCreated}`;
		const messageLinks = getLinksFromMessage(content, dateCreated, clientMessageId);
		const messageObj = {
			attachments: attachments || [],
			clientMessageId,
			content: content,
			links: messageLinks.map(l => ({ link: l.link, dateCreated: dateCreated })),
			mentions: [],
		};
		const response = await insertMessage(props.conversationId, messageObj);
		if (response.error) {
			setShowUploadPercentage(false);
		} else {
			setMessageContent('');
			setIsSendingMessage(false);
			setShowUploadPercentage(false);
			scrollToBottom();
			setHasMessages(true);
		}
	};

	const handleInsertMessage = e => {
		if (e.key === 'Enter') {
			e.preventDefault();
			if (isSendingMessage || messageContent.trim() === '') {
				return;
			}
			sendMessage();
		}
	};

	const getUserIds = () => [...conversationMembers.map(item => item.id), userInfo.userId];

	const handleOnPaste = async e => {
		const { files } = e.clipboardData || e.originalEvent.clipboardData;
		if (files.length === 0) {
			return;
		}
		setShowUploadPercentage(true);
		setError(null);
		const conversationData = {
			userIds: getUserIds(),
			conversationId: props.conversationId,
			isGroup: conversationMembers.length > 1,
		};
		const response = await uploadAttachment(files[0], false, conversationData, setFileUploadPercentage);
		if (response.error) {
			setError(response.error.Message);
			setFileUploadPercentage(0);
			setShowUploadPercentage(false);
			return;
		}
		sendMessage([response]);
	};

	const handleSubmitMessage = () => {
		if (isSendingMessage || messageContent.trim() === '') {
			return;
		}
		setIsSendingMessage(true);
		sendMessage();
	};

	const onFileUploadHandler = async event => {
		const fileToUpload = event.target.files ? event.target.files[0] : event.dataTransfer.files[0];
		setShowUploadPercentage(true);
		setError(null);
		const conversationData = {
			userIds: getUserIds(),
			conversationId: props.conversationId,
			isGroup: conversationMembers.length > 1,
		};
		const response = await uploadAttachment(fileToUpload, false, conversationData, setFileUploadPercentage);
		if (response.error) {
			setError(response.error.Message);
			setFileUploadPercentage(0);
			setShowUploadPercentage(false);
			uploadFileElement.current.value = null;
			uploadPhotoElement.current.value = null;
			return;
		}
		sendMessage([response]);
		uploadFileElement.current.value = null;
		uploadPhotoElement.current.value = null;
	};

	const scrollToBottom = () => {
		if (!writeMessageElement.current) {
			return;
		}
		writeMessageElement.current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
	};

	const onSubmitChange = e => {
		e.preventDefault();
	};

	const getPictures = attachments => attachments.filter(item => isPictureAttachment(item.typeId));

	const getOtherAttachments = attachments => attachments.filter(item => !isPictureAttachment(item.typeId));

	const getLatestDate = () => {
		if (conversationItems.length === 0) {
			return null;
		}
		const dates = conversationItems.map(item => item.dateCreated);
		return Math.min(...dates);
	};

	const getLinksFromMessage = (content, dateCreated, messageId) => {
		const matchedLinks = [];
		const match = linksRegex.exec(content);
		if (match !== null) {
			matchedLinks.push({
				link: match[0],
				messageId: messageId,
				dateCreated: dateCreated,
			});
		}
		return matchedLinks;
	};

	const processContent = (content, links = []) => {
		let result = content;
		links.forEach(item => {
			let href = '';
			if (item.link.includes('http://') || item.link.includes('https://')) {
				href = item.link;
			} else {
				href = `http://${item.link}`;
			}
			result = reactStringReplace(result, item.link, (match, i) => (
				<a target='_blank' rel='noopener noreferrer' href={href} key={match + i}>
					{match}
				</a>
			));
		});
		return (
			<>
				{links.length > 0 && <p>{result}</p>}
				{links.length === 0 && content && <p> {decodeHtml(content)}</p>}
			</>
		);
	};

	const groupConversationItems = items => {
		if (items.length === 0) {
			return items;
		}
		const convoItems = JSON.parse(JSON.stringify(items));
		convoItems.sort((a, b) => +new Date(a.dateCreated) - +new Date(b.dateCreated));
		const groups = [];
		let groupIndex = 0;

		convoItems.forEach((item, index, arr) => {
			const itemToPush = { ...item, uid: v4() };
			if (arr[index + 1]) {
				if (!groups[groupIndex]) {
					groups.push([]);
				}
				groups[groupIndex].push(itemToPush);
				if (!shouldGroupHistoryItem(itemToPush, arr[index + 1])) {
					groupIndex += 1;
				}
			} else {
				if (!groups[groupIndex]) {
					groups.push([]);
				}
				groups[groupIndex].push(itemToPush);
			}
		});
		return groups;
	};

	const shouldGroupHistoryItem = (item1, item2) => {
		const sender1 = item1.sender || item1.owner;
		const sender2 = item2.sender || item2.owner;
		return areSameObject(sender1, sender2) && (item2.dateCreated - item1.dateCreated) / 1000 / 60 < 30;
	};

	const formatEventLinks = content => {
		const result = reactStringReplace(content, /(https?:\/\/\S+)/g, (match, i) => (
			<a target='_blank' rel='noopener noreferrer' href={match} key={match + i}>
				link
			</a>
		));
		return <>{result}</>;
	};

	const getSenderFullName = item => {
		const senderId = item.sender ? item?.sender?.objectId : item.owner.objectId;
		const items = getConversationMembersInfo(props.conversationId);
		const sender = items.find(el => +senderId === +el.objectId);
		return `${sender?.firstName} ${sender?.lastName}`;
	};

	const getSenderImage = senderId => {
		const items = getConversationMembersInfo(props.conversationId);
		const sender = items.find(item => +senderId === +item.objectId);
		return sender?.profilePicture || props?.selectedUser?.profilePicture;
	};

	const processAdaptiveCardContent = item => (
		<ChatAdaptiveCard payload={JSON.parse(item.content)} conversationId={props.conversationId} />
	);

	const url = typingObject ? buildProfilePic(typingObject.user.profilePicture) : '';

	const canSendFiles = () => {
		if (!props.isHelpDesk) {
			return true;
		}
		if (
			props.isHelpDesk &&
			configurations.adminConfigurableMenu[getUserRole()][GeneralAndMenuSettings.CONVERSATION_FILES]?.value
		) {
			return true;
		}
		return false;
	};

	const canSendPhotos = () => {
		if (!props.isHelpDesk) {
			return true;
		}
		if (
			props.isHelpDesk &&
			configurations.adminConfigurableMenu[getUserRole()][GeneralAndMenuSettings.CONVERSATION_PHOTOS]?.value
		) {
			return true;
		}
		return false;
	};

	const memoizedAttachments = useMemo(() => {
		const result = [];
		conversationItems.forEach(item => {
			if (!item.attachments) {
				return;
			}
			item.attachments.forEach(attachment => {
				if ([AttachmentType.PICTURE, AttachmentType.VIDEO].includes(attachment.typeId)) {
					result.push({
						...attachment,
						sender: item.sender,
						messageId: item.id,
						dateCreated: item.dateCreated,
					});
				}
			});
		});
		return result;
	}, [conversationItems]);

	return (
		<Grid className='patient-chat-grid' columns={props.hideRightSide || fetchConversationError ? '1fr' : '2fr 1fr'}>
			<div
				className={classNames('patient-chat', {
					'patient-chat-call-view': props.isCallView,
					'patient-doctor-chat': props.isPatientDoctorChat,
					expanded: props.isExpanded,
				})}
				onScroll={handleScroll}>
				{updateReadStatusError && <p>{updateReadStatusError}</p>}
				{isLoadingConversationItems && !fetchConversationError && <span className='circle-loader' />}
				<div className='patient-chat-area' ref={patientChatElement}>
					{isDragingFile && (
						<div className='loading-overlay dropfile-overlay'>
							<span className='drop-overlay-icon'>
								<i className='material-icons drop-overlay-icon-color'>file_upload</i>
							</span>
							<span className='dropfile-first-textline'>Drop Your Files</span>
							<br />
							<span className='dropfile-second-textline'>
								Add your files by dropping them anywhere <br /> in this window
							</span>
						</div>
					)}
					{!isDragingFile && (
						<div className={props.isCallChat && isConversationLoading ? 'position-relative full-width full-height' : ''}>
							{isConversationLoading && (
								<div className='full-width flex flex-justify-center flex-align-center'>
									<span className={`loader gif-loading${props.isCallChat && isConversationLoading ? ' left-50-absolute' : ''}`}>
										<Loader />
									</span>
								</div>
							)}
							{!isConversationLoading && (
								<>
									{!hasMessages && !fetchConversationError && (
										<div
											className={`empty-state-wrapper-percent${
												props.hideRightSide && !fetchConversationError ? ' monitoring-chat-empty' : ''
											}`}>
											<EmptyState
												title={translate('noConversation', {
													value: props.teamName || props.selectedUser?.name,
												})}
												image='no-convo.svg'
												paragraph={translate('startConvo')}
											/>
										</div>
									)}
									{fetchConversationError && (
										<div className={`empty-state-wrapper-percent${props.hideRightSide ? ' monitoring-chat-empty' : ''}`}>
											<EmptyState title={translate('error')} image='no-convo.svg' paragraph={translate('somethingWentWrong')} />
										</div>
									)}
									{conversationGroups.map((group, groupIndex) => {
										return group.map((item, index, arr) => {
											const isMe = item.owner
												? +item.owner.objectId === userInfo.userId
												: +item.sender.objectId === userInfo.userId;
											const contentClasses =
												item.attachments &&
												item.attachments.length > 0 &&
												[AttachmentType.PICTURE, AttachmentType.VIDEO].includes(item.attachments[0].typeId)
													? 'chat-participant-file'
													: 'chat-participant-text';
											let messageStatusClasses = '';
											let eventClass = '';
											if (ConversationItemsType.UPCOMING_APPOINTMENT === item.typeId) {
												eventClass = 'upcoming-appointment';
											} else if (ConversationItemsType.EVENTCALL === item.typeId) {
												eventClass = 'call-event';
											} else if (ConversationItemsType.EVENT) {
												eventClass = 'missed-call-event';
											}
											if (isMe) {
												messageStatusClasses = 'sent-message';
											}
											const shouldShowProfilePic =
												index === 0 &&
												![
													ConversationItemsType.EVENT,
													ConversationItemsType.UPCOMING_APPOINTMENT,
													ConversationItemsType.EVENTCALL,
													ConversationItemsType.ADAPTIVE_CARD,
													ConversationItemsType.WALKIE_TALKIE,
												].includes(item.typeId);
											return (
												<div key={item.id}>
													{ConversationItemsType.ADAPTIVE_CARD === item.typeId && (
														<div className='adaptive-card-height adaptive-card-spacing'>{processAdaptiveCardContent(item)}</div>
													)}
													<div className={classNames('chat-box flex', isMe ? 'right-chat' : 'left-chat')}>
														<div className='flex'>
															{groupIndex === 0 && <div ref={lastLoadedElement} />}
															{shouldShowProfilePic && (
																<>
																	<ProfilePicture
																		className='chat-participant-avi'
																		fullName={!isMe ? getSenderFullName(item) : `${userInfo.firstName} ${userInfo.lastName}`}
																		profilePicture={!isMe ? getSenderImage(item) : userInfo.profilePicture}
																	/>
																</>
															)}
															<div
																className={classNames(
																	'info-content',
																	!shouldShowProfilePic ? ' info-content-wo-profile-pic' : '',
																	!shouldShowProfilePic && item.typeId === ConversationItemsType.ADAPTIVE_CARD
																		? 'info-content-adaptive-card'
																		: ''
																)}>
																{index === 0 &&
																	!isMe &&
																	![
																		ConversationItemsType.EVENT,
																		ConversationItemsType.UPCOMING_APPOINTMENT,
																		ConversationItemsType.EVENTCALL,
																		ConversationItemsType.ADAPTIVE_CARD,
																		ConversationItemsType.WALKIE_TALKIE,
																	].includes(item.typeId) && (
																		<div className='chat-participant-info'>
																			<p>{getSenderFullName(item)}</p>
																		</div>
																	)}
																{item.typeId === ConversationItemsType.MESSAGE && (
																	<div title={convertUTCDateToLocalDateOrTime(item.dateCreated)} className={contentClasses}>
																		{item.attachments &&
																			getPictures(item.attachments).map(picture => {
																				return (
																					<span
																						key={picture.id}
																						className='cursor-pointer'
																						onClick={() =>
																							setSelectedImage({
																								dateCreated: item.dateCreated,
																								extension: picture?.extension,
																								id: picture?.id,
																								name: picture?.name,
																								originalName: picture?.originalName,
																								sender: item.sender,
																								thumbnailUrl: picture?.thumbnailUrl,
																								typeId: picture?.typeId,
																								url: picture?.url,
																							})
																						}>
																						<img
																							style={{ height: picture.height }}
																							src={picture.thumbnailUrl}
																							className='chatimg'
																							onError={e => onErrorDefaultSrc(e)}
																							alt='pictures'
																							onLoad={scrollToBottom}
																						/>
																					</span>
																				);
																			})}
																		{item.attachments &&
																			getOtherAttachments(item.attachments).map(attachment => {
																				return (
																					<div key={attachment.id}>
																						{isDocumentAttachment(attachment.typeId) && (
																							<div>
																								<p>
																									{translate('document')}: {attachment.originalName}
																								</p>
																								<p>
																									<a className='download-document' href={attachment.url}>
																										{translate('download')}
																									</a>
																								</p>
																							</div>
																						)}
																						{isVideoAttachment(attachment.typeId) && (
																							<div>
																								<video controls style={{ height: attachment.height }}>
																									<source
																										src={attachment.url}
																										type={`video/${removeSpecialCharacters(attachment.extension)}`}
																									/>
																								</video>
																							</div>
																						)}
																					</div>
																				);
																			})}
																		{processContent(item.content, item.links)}
																	</div>
																)}

																{index === arr.length - 1 &&
																	![
																		ConversationItemsType.EVENT,
																		ConversationItemsType.UPCOMING_APPOINTMENT,
																		ConversationItemsType.EVENTCALL,
																		ConversationItemsType.WALKIE_TALKIE,
																	].includes(item.typeId) && (
																		<div className='chat-participant-time'>
																			<div>
																				<div className={messageStatusClasses} />
																				<span>{getFormattedDate(item.dateCreated)}</span>
																			</div>
																		</div>
																	)}
															</div>
														</div>
													</div>
													{[
														ConversationItemsType.EVENT,
														ConversationItemsType.UPCOMING_APPOINTMENT,
														ConversationItemsType.EVENTCALL,
													].includes(item.typeId) && (
														<div className={eventClass}>
															<p className='chat-participant-event'>{formatEventLinks(item.content)}</p>
															<span>{getFormattedDate(item.dateCreated)}</span>
														</div>
													)}

													{ConversationItemsType.WALKIE_TALKIE === item.typeId && (
														<div className={`walkie-talkie-event ${isMe ? '' : 'walkie-talkie-flex-start'}`}>
															<div className='walkie-talkie-wrapper cursor-pointer'>
																<div
																	className='walkie-talkie-tooltip-wrapper'
																	data-tooltip={!isMe ? getSenderFullName(item) : `${userInfo.firstName} ${userInfo.lastName}`}
																	data-position='top'>
																	<ProfilePicture
																		className='chat-participant-avi talkie-walkie-talkie-profile'
																		fullName={!isMe ? getSenderFullName(item) : `${userInfo.firstName} ${userInfo.lastName}`}
																		profilePicture={!isMe ? getSenderImage(item.owner.objectId) : userInfo.profilePicture}
																	/>
																</div>
																<div className='position-relative'>
																	<div className='walkie-talkie-icon'>
																		<img src={`${monitoringIconLink}video-feed/walkie-talkie.svg`} alt='ico' />
																	</div>
																</div>
																<i className='material-icons play-icon'>play_arrow</i>
																<div className='position-relative'>
																	<p className='walkie-talkie-duration'>0:05</p>
																</div>
															</div>
															<span>{getFormattedDate(item.dateCreated)}</span>
														</div>
													)}
												</div>
											);
										});
									})}
								</>
							)}
						</div>
					)}
					{showUploadPercentage && (
						<div className='image-progress-bar'>
							<div style={{ width: `${fileUploadPercentage}%` }} />
							<span>{`${fileUploadPercentage}%`}</span>
						</div>
					)}
					{!!typingObject && (
						<div className='chat-typing type-animation'>
							<div className='user-typing'>
								<img src={url} alt='profile-img' />
								<div className='bounce1' />
								<div className='bounce2' />
								<div className='bounce2' />
							</div>
						</div>
					)}
					{!isConversationLoading && <span ref={writeMessageElement} />}
				</div>
				<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
				{!isConversationLoading && !fetchConversationError && (
					<div className='write-message'>
						<form onSubmit={onSubmitChange}>
							<textarea
								maxLength={maxCharacterLength}
								onKeyDown={e => handleInsertMessage(e)}
								value={messageContent}
								onChange={e => setMessageContent(e.target.value)}
								onPaste={handleOnPaste}
								name='messageContent'
								placeholder={intl.formatMessage({ id: 'writeMessage' })}
							/>
							<div className='message-send'>
								{canSendFiles() && (
									<>
										<label className='folder' htmlFor='file-input'>
											<div className='message-send-icon' data-cy='uploadFileBtn' />
										</label>
										<input id='file-input' ref={uploadFileElement} type='file' onChange={onFileUploadHandler} />{' '}
									</>
								)}
								{canSendPhotos() && (
									<>
										<label className='image' htmlFor='photo-input'>
											<div className='message-send-icon' data-cy='uploadPhotoBtn' />
										</label>
										<input
											ref={uploadPhotoElement}
											id='photo-input'
											type='file'
											accept='image/*'
											onChange={onFileUploadHandler}
										/>
									</>
								)}

								<label className='send' htmlFor='submit-message'>
									<div className='message-send-icon' data-cy='sendMessageBtn' onClick={handleSubmitMessage} />
								</label>
							</div>
						</form>
					</div>
				)}
				<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
			</div>
			{selectedImage && (
				<MediaViewer
					display={true}
					onModalClose={() => setSelectedImage(null)}
					attachments={memoizedAttachments}
					selectedAttachment={selectedImage}
					conversationId={props.conversationId}
					setSelectedAttachment={setSelectedImage}
					members={conversationMembers}
				/>
			)}
			{!props.hideRightSide && !fetchConversationError && props.conversationId && (
				<ChatRightSide
					conversationId={props.conversationId}
					members={conversationMembers}
					conversationName={props.teamName || 'Group'}
					isFromDoctor={props.isFromDoctor}
					isDarkMode={props.isDarkMode}
				/>
			)}
		</Grid>
	);
};

export default Conversation;
