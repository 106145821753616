import React, { useContext, useMemo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import classNames from 'classnames';
import { actionCreators as healthSystemsActionCreators } from 'state/healthSystems/actions.js';
import Grid from 'components/Grid.jsx';
import LeftNavigation from 'components/LeftNavigation.jsx';
import {
	getAdminMenu,
	getDigitalClinicianMenuDefault,
	getNurseMenu,
	getDoctorMenu,
	getPatientMenu,
	getSuperAdminMenu,
	getSuperUserMenu,
	getDigitalClinicianMenuPrimaryCare,
	getPrimaryCareDoctorMenu,
	getVisitorMenu,
	getLaborantMenu,
	getRadiologyMenu,
	getInjectionMenu,
	getInfusionMenu,
	getVirtualSitterMenu,
	getDefaultVCPmenu,
} from 'constants/navigation.js';
import MainHeader from 'views/Partials/MainHeader.jsx';
import { getUserId, getUserRole } from 'infrastructure/auth.js';
import { AlertTypes, AssignedRequestTypes, HealthSystemType, RequestStatus, TaskStatus, UserRoles } from 'constants/enums.js';
import SocketEvents from 'constants/socket-events.js';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';
import Alert from 'components/Alert.jsx';
import Form from 'components/Form.jsx';
import Modal from 'components/Modal.jsx';
import translate from 'i18n-translations/translate.jsx';
import { getMyPatients } from 'api/patients.js';
import useScreenType from 'hooks/useScreenType.js';
import { actionCreators as patientActionCreators } from 'state/patients/actions.js';
import { getPatientQueueCount } from 'api/users.js';
import { getLevelAssignedRequests, getProviderAssignedRequests } from 'api/doctorRequests.js';
import {
	getConfigurationMenu,
	getConfigurationValue,
	getNursePoolingStatus,
	getRoleConfigurationValue,
	getSomeRoleConfigurationsValues,
	getStorage,
} from 'infrastructure/helpers/commonHelpers.js';
import { RoomTypes } from 'constants/visitEnums.js';
import { TelemedicineModesSettings, GeneralAndMenuSettings } from 'constants/configurationEnums.js';
import { actionCreators as userActionCreators } from 'state/user/actions.js';
import { LOCALES } from 'i18n-translations/locales.js';
import DarkTheme from 'calls/styles/DarkTheme.js';
import Loader from 'components/Loader.jsx';
import LeftNavigationMayo from 'components/LeftNavigationMayo.jsx';
import PopUpAlert from 'components/PopUpAlert.jsx';
import useHasFocus from 'components/UseHasFocus.jsx';
import { newSessionSound } from 'constants/soundAlerts.js';
import { getAdminGeneralSettingConfigValue } from 'infrastructure/helpers/configurationsHelpers.js';

const MainLayout = props => {
	const isBrowserTabFocused = useHasFocus();
	const companyConfigurations = useSelector(state => state.company.companySettings?.companyConfigurations);
	const customRoleConfigurations = useSelector(state => state.configurations.customRoleConfigurations);
	const [isNewPatientCheckInModalVisible, setIsNewPatientCheckInModalVisibility] = useState(false);
	const [selectedPage, setSelectedPage] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [taskCompleted, setTaskCompleted] = useState(null);
	const dispatch = useDispatch();
	const toggleLeftNavigation = () => dispatch(healthSystemsActionCreators.toggleLeftNavigation());
	const healthSystems = useSelector(state => state.healthSystems);
	const company = useSelector(state => state.company);
	const user = useSelector(state => state.user);
	const locale = useSelector(state => state.language.locale);
	const configurations = useSelector(state => state.configurations);
	const setUserWaitingRoomCount = count => dispatch(userActionCreators.setUserWaitingRoomCount(count));
	const setUserSessionQueueCount = count => dispatch(userActionCreators.setUserSessionQueueCount(count));
	const socket = useContext(SocketContext);
	const history = useHistory();
	const screenType = useScreenType();
	const selectedPatient = useSelector(state => state.myPatients.selectedPatient);
	const isAssignedToDoctors = useSelector(state => state.user.isAssignedToDoctors);

	const treeHierarchyTypeId = healthSystems.allHealthSystems.find(
		item => item.id === user.userSession.healthSystem.id
	)?.treeHierarchyTypeId;
	const isNewExperience = useSelector(state => state.configurations.isNewExperience);

	const shouldApplyResponsive = screenType.isSmall && (healthSystems.isLeftNavigationExpanded || healthSystems.isRightPanelOpen);

	const isPrimaryCareType = useMemo(() => {
		return (
			healthSystems?.allHealthSystems?.find(item => item.id === user.userSession.healthSystem.id)?.workflowTypeId ===
			HealthSystemType.PRIMARY_CARE
		);
	}, [healthSystems?.allHealthSystems, user.userSession.healthSystem.id]);

	useEffect(() => {
		let newRequestSound = newSessionSound;
		const handleRequestUpdated = async data => {
			const { requestStatusId, assignedRequestTypeId } = data;
			if (history.location.pathname !== '/sessions-queue' && assignedRequestTypeId === AssignedRequestTypes.WITH_INTAKE_FORM) {
				setUserSessionQueueCount(user.waitingRoomCount + 1);
			} else if (!['/waiting-room', '/doctor-home'].includes(history.location.pathname) || !isBrowserTabFocused) {
				setUserWaitingRoomCount(user.waitingRoomCount + 1);
				if (
					getRoleConfigurationValue(customRoleConfigurations, GeneralAndMenuSettings.SOUND_NOTIFICATION) &&
					requestStatusId === RequestStatus.PENDING
				) {
					try {
						newRequestSound.loop = false;
						await newRequestSound.play();
					} catch (error) {
						console.log('Autoplay failed:', error);
					}
				}
			}
		};

		const getEventBasedOnConfig = () =>
			getConfigurationValue(companyConfigurations[TelemedicineModesSettings.SHARED_QUEUE])
				? SocketEvents.HealthCare.ON_LEVEL_REQUEST_UPDATED
				: SocketEvents.HealthCare.ON_MEDIC_REQUEST_UPDATED;

		if ([UserRoles.DOCTOR, UserRoles.NURSE].includes(getUserRole())) {
			socket.on(getEventBasedOnConfig(), handleRequestUpdated);
		}
		return () => {
			newRequestSound = null;
			if ([UserRoles.DOCTOR, UserRoles.NURSE].includes(getUserRole())) {
				socket.off(getEventBasedOnConfig(), handleRequestUpdated);
			}
		};
	}, [socket, dispatch, history.location.pathname]);

	useEffect(() => {
		const handleQueueUpdated = data => {
			const { assignedRequestTypeId } = data;
			if (assignedRequestTypeId === AssignedRequestTypes.WITHOUT_INTAKE_FORM) {
				setUserWaitingRoomCount(user.waitingRoomCount + 1);
			}
			if (assignedRequestTypeId === AssignedRequestTypes.WITH_INTAKE_FORM) {
				setUserSessionQueueCount(user.waitingRoomCount + 1);
			}
		};
		socket.on(SocketEvents.HealthCare.PATIENT_QUEUE_UPDATED, handleQueueUpdated);
		return () => {
			socket.off(SocketEvents.HealthCare.PATIENT_QUEUE_UPDATED, handleQueueUpdated);
		};
	}, [socket, user.waitingRoomCount]);

	useEffect(() => {
		const handleTaskCompleted = data => {
			if (['/rpm-patient-onboarding', '/check-in-patient', '/dashboard/detailed-report'].includes(history.location.pathname)) {
				return;
			}
			if (data.taskStatusId === TaskStatus.COMPLETED) {
				setTaskCompleted(translate('taskCompleted'));
			}
			if (data.taskStatusId === TaskStatus.FAULTED) {
				setTaskCompleted(translate('somethingWentWrong'));
			}
		};
		socket.on(SocketEvents.BACKGROUND.TASK_UPDATED, handleTaskCompleted);
		return () => {
			socket.off(SocketEvents.BACKGROUND.TASK_UPDATED, handleTaskCompleted);
		};
	}, [socket]);

	useEffect(() => {
		const hasActiveQueue = async () => {
			const response = await getPatientQueueCount(getUserId());
			if (!response.error) {
				setUserWaitingRoomCount(response.queueSize);
			}
		};

		const getRequests = async () => {
			const params = {
				doctorGuidId: getUserId(),
				type: AssignedRequestTypes.WITHOUT_INTAKE_FORM,
			};
			const response = getConfigurationValue(companyConfigurations[TelemedicineModesSettings.SHARED_QUEUE])
				? await getLevelAssignedRequests({ healthSystemId: user.userSession.healthSystem.id, ...params })
				: await getProviderAssignedRequests(params);
			if (!response.error) {
				setUserWaitingRoomCount(response.totalCount);
			}
		};
		if (getUserRole() === UserRoles.DOCTOR && !isPrimaryCareType) {
			getRequests();
		}
		if (isPrimaryCareType && [UserRoles.DIGITAL_CLINICIAN, UserRoles.DOCTOR, UserRoles.NURSE].includes(getUserRole())) {
			hasActiveQueue();
		}
	}, [isPrimaryCareType]);

	useEffect(() => {
		const getPatients = async () => {
			if (UserRoles.DOCTOR !== getUserRole()) {
				return;
			}
			const patients = await getMyPatients({
				pageIndex: 0,
				pageSize: 20,
			});
			if (!selectedPatient) {
				dispatch(patientActionCreators.setSelectedPatient(patients.length > 0 ? patients[0] : null));
			}
		};
		getPatients();
	}, [dispatch, selectedPatient]);

	const onLeftNavigationItemClicked = (item, event) => {
		const checkInLocation = '/check-in-patient';
		const patientId = getStorage().getItem('patientId');
		if (item.role === 'toggleLeftNavigation' || history.location.pathname === checkInLocation) {
			if (patientId && history.location.pathname === checkInLocation) {
				event.preventDefault();
				openCheckInNewPatient(item.linkTo);
			} else {
				history.push(item.linkTo);
			}
			if (item.role === 'toggleLeftNavigation') {
				toggleLeftNavigation();
			}
		}
	};

	const openCheckInNewPatient = link => {
		const checkInLocation = '/check-in-patient';
		const patientId = getStorage().getItem('patientId');
		if (patientId && history.location.pathname === checkInLocation) {
			setIsNewPatientCheckInModalVisibility(true);
			setSelectedPage(link);
			return;
		}
		history.push(link || checkInLocation);
	};

	const checkInNewPatient = () => {
		getStorage().removeItem('patientId');
		if (!selectedPage) {
			history.push('/check-in-patient');
		} else {
			history.push(selectedPage);
		}
		setIsNewPatientCheckInModalVisibility(false);
	};

	const closeCheckInModal = () => {
		setSelectedPage('');
		setIsNewPatientCheckInModalVisibility(false);
	};

	const getNavigation = () => {
		const role = getUserRole();
		if (!role) {
			return [];
		}
		const isPrimaryCare = user.userSession.healthSystem.workflowTypeId === HealthSystemType.PRIMARY_CARE;
		const adminConfigurableMenu = getConfigurationMenu(configurations.adminConfigurableMenu, getUserRole());
		const configurableMenu = getConfigurationMenu(configurations.configurableMenu, getUserRole());
		const isSubConfigActive = getSomeRoleConfigurationsValues(customRoleConfigurations, [
			GeneralAndMenuSettings.RPM,
			GeneralAndMenuSettings.CPM,
		]);

		const nurseParams = {
			configurations,
			waitingRoomCount: user.waitingRoomCount,
			treeHierarchyTypeId,
			patientId: selectedPatient?.userId,
			isAssignedToDoctors,
			isSubConfigActive,
		};

		const doctorParams = {
			configurations,
			healthSystems: healthSystems?.allHealthSystems,
			patientId: selectedPatient?.userId,
			isSubConfigActive,
		};

		switch (role) {
			case UserRoles.SUPER_ADMIN:
				return getSuperAdminMenu(configurations, locale);
			case UserRoles.ADMIN:
				return getAdminMenu(configurations, locale);
			case UserRoles.SUPER_USER:
				return getSuperUserMenu(configurations, locale);
			case UserRoles.PATIENT:
				return getPatientMenu(configurations, locale);
			case UserRoles.VIRTUAL_SITTER:
				return getVirtualSitterMenu(configurations, treeHierarchyTypeId);
			case UserRoles.DOCTOR: {
				if (isPrimaryCare) {
					return getPrimaryCareDoctorMenu(configurations, user.userSession);
				}
				return getDoctorMenu(doctorParams);
			}
			case UserRoles.NURSE: {
				if (
					!getStorage().getItem('iframe_integration_id') &&
					!getNursePoolingStatus() &&
					getConfigurationValue(adminConfigurableMenu[GeneralAndMenuSettings.POOLING_FLOW]) &&
					getConfigurationValue(configurableMenu[GeneralAndMenuSettings.POOLING_FLOW])
				) {
					return [];
				}
				return isPrimaryCare ? getVcpMenu() : getNurseMenu(nurseParams);
			}
			case UserRoles.DIGITAL_CLINICIAN: {
				if (isPrimaryCare) {
					return getDigitalClinicianMenuPrimaryCare(configurations, user.userSession);
				}
				return getDigitalClinicianMenuDefault(configurations, user.userSession);
			}
			case UserRoles.VISITOR: {
				return getVisitorMenu();
			}
			default: {
				return [];
			}
		}
	};

	const getVcpMenu = () => {
		let result = [];
		switch (user.userSession.channelTypeId) {
			case RoomTypes.LABORATORY.type: {
				result = getLaborantMenu(configurations, user.userSession);
				break;
			}
			case RoomTypes.INFUSION_ROOM.type: {
				result = getInfusionMenu(configurations, user.userSession);
				break;
			}
			case RoomTypes.INJECTION_ROOM.type: {
				result = getInjectionMenu(configurations, user.userSession);
				break;
			}
			case RoomTypes.RADIOLOGY.type: {
				result = getRadiologyMenu(configurations, user.userSession);
				break;
			}
			default: {
				result = getDefaultVCPmenu();
				break;
			}
		}
		return result;
	};

	const getDarkModeLoaderColor = () =>
		user.darkMode &&
		[UserRoles.DOCTOR, UserRoles.NURSE, UserRoles.VIRTUAL_SITTER].includes(getUserRole()) &&
		DarkTheme.colors.grayThree;
	const isMonitoringPage = ['/monitoring', '/care-notifications'].includes(history.location.pathname) && isNewExperience;

	const getAlertMessageContent = data => ({
		title: data.measurementAlertType.name,
		contentText: translate('patientHasMeasured', {
			value1: data.patientFullName,
			value2: data.measurementAlertType.name,
		}),
	});

	const handleMeasurementAlertClick = () => {
		if (
			healthSystems.measurementAlertData.patientUserId &&
			getAdminGeneralSettingConfigValue(configurations, GeneralAndMenuSettings.PATIENTS) &&
			getRoleConfigurationValue(customRoleConfigurations, GeneralAndMenuSettings.ALERT_HISTORY)
		) {
			const url = `/patients/${healthSystems.measurementAlertData.patientUserId}/alertHistory`;
			dispatch(healthSystemsActionCreators.setNewMeasurementsAlertData(null));
			history.push(url);
		}
	};

	return (
		<>
			{isLoading && (
				<Grid
					columns='1fr'
					rows='1fr'
					stretch='100vh'
					horizAlign='center'
					vertAlign='center'
					backgroundColor={getDarkModeLoaderColor()}>
					<Loader />
				</Grid>
			)}
			{!isLoading && (
				<Grid
					columns={healthSystems.isFullscreen || shouldApplyResponsive ? '1fr' : '80px 1fr'}
					stretch='100dvh'
					className={classNames(
						'main-layout',
						isNewExperience ? 'new-experience' : 'old-experience',
						locale === LOCALES.ARABIC ? 'direction-rtl-wrapper' : '',
						[UserRoles.VISITOR, UserRoles.FAMILY_MEMBER].includes(getUserRole()) ? 'visitor-view' : '',
						healthSystems.isFullscreen ? 'full-screen-wrapper' : '',
						user.darkMode &&
							[UserRoles.NURSE, UserRoles.DOCTOR, UserRoles.VIRTUAL_SITTER].includes(getUserRole()) &&
							!isPrimaryCareType
							? 'dark-mode'
							: ''
					)}>
					{!healthSystems.isFullscreen && (
						<>
							{!isNewExperience && (
								<LeftNavigation
									links={getNavigation()}
									onItemClicked={onLeftNavigationItemClicked}
									companyDetails={company.companyDetails}
									companySettings={company.companySettings}
									openCheckInNewPatient={openCheckInNewPatient}
									hasName={false}
									isPrimaryCareType={isPrimaryCareType}
									setIsLoading={setIsLoading}
								/>
							)}
							{isNewExperience && (
								<LeftNavigationMayo
									links={getNavigation()}
									onItemClicked={onLeftNavigationItemClicked}
									companyDetails={company.companyDetails}
									companySettings={company.companySettings}
									openCheckInNewPatient={openCheckInNewPatient}
									hasName={false}
									isPrimaryCareType={isPrimaryCareType}
									setIsLoading={setIsLoading}
								/>
							)}
						</>
					)}
					<div className='app-wrapper'>
						{!isMonitoringPage && (
							<MainHeader
								hasName={false}
								openCheckInNewPatient={openCheckInNewPatient}
								isMonitoring={props.isMonitoring}
								isPrimaryCareType={isPrimaryCareType}
								setIsLoading={setIsLoading}
							/>
						)}
						<Grid
							className={props.isSecondColumnCollapsed ? 'collapsed-second-column' : ''}
							columns={props.twoColumns ? '1fr 3fr' : '1fr'}
							stretch='100%'>
							<div className='full-width'>{props.children}</div>
						</Grid>
						{props.showWarningAlert && healthSystems.measurementAlertData && (
							<PopUpAlert
								display={healthSystems.measurementAlertData}
								isRightBottom={true}
								title={getAlertMessageContent(healthSystems.measurementAlertData).title}
								alertType={AlertTypes.DANGER}
								contentText={getAlertMessageContent(healthSystems.measurementAlertData).contentText}
								isSilent={true}
								onTextClick={handleMeasurementAlertClick}
								onAlertClose={() => dispatch(healthSystemsActionCreators.setNewMeasurementsAlertData(null))}
								isMeasurementAlert={true}
								selfCloseTimeOut={5000}
							/>
						)}
					</div>
				</Grid>
			)}
			{isNewPatientCheckInModalVisible && (
				<Modal
					display={isNewPatientCheckInModalVisible}
					onModalClose={closeCheckInModal}
					onModalSubmit={checkInNewPatient}
					primaryButtonLabel={translate('discardAndContinue')}
					className='wrapper-modal border-radius-modal-wrapper appoinment-next-arrow-modal'
					shouldSubmitOnEnter={false}
					position='center'>
					<Form height={220} className='discard-changes-form'>
						<h3>{translate('discardChanges')}</h3>
						<p>{translate('checkInOtherPatient')}</p>
					</Form>
				</Modal>
			)}
			<div className='build-number'>
				<Alert
					display={taskCompleted}
					fixed={true}
					variant='dark'
					message={translate('taskCompleted')}
					onClose={() => setTaskCompleted(null)}
				/>
			</div>
		</>
	);
};

export default MainLayout;
