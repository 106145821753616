const SocketEvents = {
	Client: {
		AUTHORIZE: 'client.authorize',
		PING: 'client.ping',
		UPDATE_USER_PRESENCE: 'client.updateUserPresence',
		// subscribers
		ON_USER_ONLINE: 'client.userOnline',
		ON_USER_OFFLINE: 'client.userOffline',
		ON_AUTHENTICATED: 'client.authenticated',
		ON_UNAUTHORIZED: 'client.unauthorized',
		ON_CONNECT: 'connect',
		ON_CONNECT_FAILED: 'connect_failed',
		ON_CONNECT_ERROR: 'connect_error',
		ON_RECONNECT: 'reconnect',
		ON_RECONNECTING: 'reconnecting',
		ON_DISCONNECT: 'disconnect',
		ON_DEVICE_ONLINE: 'client.deviceOnline',
		ON_DEVICE_OFFLINE: 'client.deviceOffline',
		ON_UPDATED_USER_PRESENCE: 'client.updatedUserPresence',
		ON_NOTIFY_UPDATED_USER_PRESENCE: 'client.notifyUpdatedUserPresence',
		ON_DOCTOR_WORKFLOW_UPDATED: 'healthcare.doctorWorkflowUpdated',
		UNSUBSCRIBE_FROM_HS_EVENTS: 'client.unSubscribeFromHSEvents',
		SUBSCRIBE_TO_HS_EVENTS: 'client.subscribeToHSEvents',
	},
	Conference: {
		START: 'conference.start',
		JOIN: 'conference.join',
		LEAVE: 'conference.leave',
		END: 'conference.end',
		TOGGLE_STREAMS: 'conference.toggleStreams',
		AUDIO_TRACK_TOGGLED: 'conference.audioTrackToggled',
		ERROR: 'conference.error',
		SEND_OFFER: 'conference.sendOffer',
		SEND_ANSWER: 'conference.sendAnswer',
		SEND_ICE_CANDIDATE: 'conference.sendIceCandidate',
		NOT_ANSWERING: 'conference.notAnswering',
		CLOSING_CLIENT: 'conference.closingClient',
		DECLINE: 'conference.decline',
		BROWSER_NOT_SUPPORTED: 'conference.browserNotSupported',
		SDP: 'conference.sdp',
		TRACK_TOGGLE: 'conference.toggleParticipantTrack',
		TRACKS_ADDED: 'conference.tracks.added',
		TRACKS_REMOVED: 'conference.tracks.removed',
		UA: 'conference.ua',
		DATA_RECEIVED: 'conference.data.received',
		CLOSED: 'conference.closed',
		HELLODEVICE_LEAVE: 'conference.helloDevice.leave',
		INVITE_PARTICIPANTS: 'conference.inviteParticipants',
		REMOVE_PARTICIPANT: 'conference.removeParticipant',
		PARTICIPANT_RECONNECT: 'conference.participantReconnect',
		PARTICIAPNT_CHANGE_BITRATE: 'conference.requestToChangeBitrate',
		PEER_CONNECTION_STATE_CHANGED: 'conference.peerConnectionStateChanged',
		ICE_GATHERING_STATE_CHANGED: 'conference.iceGatheringStateChanged',
		ICE_CONNECTION_STATE_CHANGED: 'conference.iceConnectionStateChanged',
		LOG: 'conference.log',
		ROOM_JOIN: 'conference.room.join',
		LOGGER: 'conference.logger',
		GET_INVITATION_LINKS: 'conference.getInvitationLinks',
		REMOVE_INVITATION_LINKS: 'conference.removeInvitationLinks',
		ALERT_PATIENT_AT_RISK: 'conference.helloDevice.alertPatientAtRisk',
		ALERT_PATIENT_AT_RISK_RESPONSE: 'conference.helloDevice.alertPatientAtRisk.response',
		// subscribers
		ON_PARTICIPANT_NOT_ANSWERING: 'conference.participantNotAnswering',
		ON_PARTICIPANT_DECLINED: 'conference.participantDeclined',
		ON_PARTICIPANT_LEFT: 'conference.participantLeft',
		ON_PARTICIPANT_STREAMS_TOGGLED: 'conference.participantStreamsToggled',
		ON_NEW_ANSWER: 'conference.newAnswer',
		ON_NEW_OFFER: 'conference.newOffer',
		ON_NEW_ICE_CANDIDATE: 'conference.newIceCandidate',
		ON_NEW_PARTICIPANT: 'conference.newParticipant',
		ON_REMOVE_SCREENSHARE_PEER: 'conference.helloDevice.removeScreenSharePeer',
		ON_RINGING: 'conference.ringing',
		ON_INCOMING: 'conference.incoming',
		ON_PARTICIPANT_OFFLINE: 'conference.participantOffline',
		ON_PARTICIPANT_BUSY: 'conference.participantBusy',
		ON_INITIATOR_LEFT: 'conference.initiatorLeft',
		ON_UPDATE_PARTICIPANTS: 'conference.updateParticipants',
		ON_MISSED_CALL: 'conference.missedCall',
		ON_DEVICE_CONTROLS_LOCKED: 'conference.deviceControlsLocked',
		ON_ENDED: 'conference.ended',
		ON_ANSWERED_ELSEWHERE: 'conference.answeredElsewhere',
		ON_PARTICIPANT_REMOVED: 'conference.participantRemoved',
		ON_TRANSFERRED_TO_ANOTHER_CLIENT: 'conference.transferredToAnotherClient',
		ON_TOGGLE_TRACK: 'conference.toggleTrack',
		ON_CAMERA_MEASUREMENTS: 'conference.vitalSigns.cameraMeasurements',
		ON_CAMERA_MEASUREMENTS_TOGGLE: 'conference.vitalSigns.cameraMeasurementsToggle',
		IS_ACTIVE: 'conference.isActive',
		WALKIE_TALKIE_VOICE_ACTIVITY: 'conference.walkieTalkieVoiceActivity',
		START_DEVICE_CALL: 'conference.helloDevice.start',
		FORCE_TERMINATE: 'conference.forceTerminate',
		ON_TERMINATE_REQUEST: 'conference.newTerminateRequest',
		ON_TERMINATE_REQUEST_ACCEPTED: 'conference.terminateRequest.accepted',
		PARTICIPANT_FORCE_TERMINATE: 'conference.participantForceTerminate',
		TERMINATE_REQUEST: 'conference.terminateRequest',
		TERMINATE_REQUEST_ACCEPT: 'conference.terminateRequest.accept',
		TERMINATE_REQUEST_CANCEL: 'conference.terminateRequest.cancel',
		RECORD_MONITORING: 'conference.helloDevice.recordVideoFeed',
		RECORD_MONITORING_RESPONSE: 'conference.helloDevice.recordVideoFeedResponse',
		AI_CONTROLS_EVENT_DISABLE: 'conference.helloDevice.aiControlsEventsDisable',
		ON_MEDIA_CONTROLS: 'conference.helloDevice.mediaControls',
		ON_MEDIA_CONTROLS_RESPONSE: 'conference.helloDevice.mediaControls.response',
		ON_CALL_STATE_CHANGED: 'conference.helloDevice.stateChanged',
		SEND_MEASUREMENT_BUFFER_DATA: 'conference.helloDevice.sendMeasurementBufferData',
		SET_IOT_DEVICE_EXAMINATION_POINT: 'conference.iotDevice.setExaminationPoint',
		INTERPRETER_ASSINGMENT_REQUEST: 'conference.interpreterAssignmentRequest',
		INTERPRETER_RESOURCE_UPDATED: 'conference.interpreterResourceUpdated',
		LIFE_SIGNALS_TOGGLE: 'conference.lifeSignals.toggle',
		VOICE_OVER_ALERT: 'conference.helloDevice.voiceOverAlert',
		VOICE_OVER_ALERT_RESPONSE: 'conference.helloDevice.voiceOverAlert.response',
		REQUEST_DEVICE_STATE: 'conference.helloDevice.requestDeviceState',
		REQUEST_DEVICE_STATE_RESPONSE: 'conference.helloDevice.requestDeviceState.response',
		MEASUREMENT_IMAGE_UPLOADED: 'conference.measurementImageUploaded',
		PLAY_VERBAL_REDIRECTION: 'conference.helloDevice.playVerbalRedirection',
		PLAY_VERBAL_REDIRECTION_RESPONSE: 'conference.helloDevice.playVerbalRedirection.response',
		AMBIENT_MONITORING_TOGGLE: 'conference.helloDevice.ambientMonitoringToggle',
		TOGGLE_CC: 'conference.helloDevice.toggleCC',
		TOGGLE_CC_RESPONSE: 'conference.helloDevice.toggleCC.response',
	},
	Conversation: {
		SEND_MESSAGE: 'conversation.sendMessage',
		CLEAR_UNREAD_MESSAGES: 'conversation.clearUnreadMessages',
		CREATE: 'conversation.create',
		CLEAR_HISTORY: 'conversation.clearHistory',
		CHANGE_NAME: 'conversation.changeName',
		CHANGE_PICTURE: 'conversation.changePicture',
		CHANGE_BLOCK_STATUS: 'conversation.changeBlockStatus',
		ADD_MEMBERS: 'conversation.addMembers',
		REMOVE_MEMBERS: 'conversation.removeMembers',
		LEAVE: 'conversation.leave',
		JOIN: 'conversation.join',
		CHANGE_MUTE_STATUS: 'conversation.changeMuteStatus',
		DELETE_MESSAGE: 'conversation.deleteMessage',
		SET_TYPING: 'conversation.setTyping',
		RECALL_MESSAGE: 'conversation.recallMessage',
		CHANGE_PIN_STATUS: 'conversation.changePinStatus',
		CHANGE_READ_STATUS: 'conversation.changeReadStatus',

		// subscribers
		ON_NEW_MESSAGE: 'conversation.newMessage',
		ON_MESSAGE_SENT: 'conversation.messageSent',
		ON_MESSAGE_STATUS_CHANGED: 'conversation.messageStatusChanged',
		ON_NEW: 'conversation.new',
		ON_UPDATE: 'conversation.update',
		ON_LEFT: 'conversation.left',
		ON_CLEAR_UNREAD_MESSAGES: 'conversation.clearUnreadMessages',
		ON_NAME_CHANGED: 'conversation.nameChanged',
		ON_PICTURE_CHANGED: 'conversation.pictureChanged',
		ON_BLOCK_STATUS_CHANGED: 'conversation.blockStatusChanged',
		ON_CONTACT_BLOCK_STATUS_CHANGED: 'conversation.contactBlockStatusChanged',
		ON_REMOVED_FROM_MEMBERS: 'conversation.removedFromMembers',
		ON_MUTE_STATUS_CHANGED: 'conversation.muteStatusChanged',
		ON_MESSAGE_DELETED: 'conversation.messageDeleted',
		ON_SET_TYPING: 'conversation.setTyping',
		ON_MESSAGE_RECALLED: 'conversation.messageRecalled',
		ON_PIN_STATUS_CHANGED: 'conversation.pinStatusChanged',
		ON_READ_STATUS_CHANGED: 'conversation.readStatusChanged',
		ON_VIDEO_CONVERTED: 'conversation.videoConverted',
		ON_MEMBERS_ADDED: 'conversation.membersAdded',
		ON_MEMBERS_REMOVED: 'conversation.membersRemoved',
		ON_MUTE_END_DATE_CHANGED: 'conversation.muteEndDateChanged',
		ON_UNREAD_MESSAGES_COUNT_CHANGED: 'conversation.unreadMessagesCountChanged',
		ON_HISTORY_CLEARED: 'conversation.historyCleared',
		ON_NEW_EVENT: 'conversation.newEvent',
		LAST_ACTIVITY_UPDATED: 'conversation.lastActivityUpdated',
	},
	HelloDevice: {
		MOVE_CAMERA: 'helloDevice.moveCamera',
		ZOOM_CAMERA: 'helloDevice.zoomCamera',
		SWITCH_CAMERA: 'helloDevice.switchCamera',
		COMMAND: 'helloDevice.command',
		DATA_CHANNEL_OFFER: 'helloDevice.dataChannelOffer',
		DATA_CHANNEL_ANSWER: 'helloDevice.dataChannelAnswer',
		NEW_ICE_CANDIDATE: 'helloDevice.newIceCandidate',
		SEND_ICE_CANDIDATE: 'helloDevice.sendIceCandidate',
		STOP_PEER_CONNECTION: 'helloDevice.stopPeerConnection',
		ON_TOGGLE_TV: 'helloDevice.toggleTv',
		TOGGLE_NIGHTVISION: 'helloDevice.toggleNightVision',
		ON_OFFLINE: 'helloDevice.offline',
		ON_BUSY: 'helloDevice.busy',
		ON_CAMERA_RESPONSE: 'helloDevice.cameraEventResponse',
		ON_INITIAL_STATE: 'helloDevice.initialState',
		ON_UPDATE: 'helloDevice.update',
		ON_TV_RESPONSE: 'helloDevice.tvEventResponse',
		PATIENT_INFO: 'helloDevice.patientInfo',
		PATIENT_HEALTH_MEASUREMENTS: 'helloDevice.patientHealthMeasurements',
		PATIENT_STETHOSCOPE_UPLOADED_RECORD: 'helloDevice.stethoscopeUploadedRecord',
		PATIENT_OBJECT_DETECTIONS: 'helloDevice.objectDetections',
		TOGGLE_AI_TOOLS: 'helloDevice.toggleAiTools',
		SEND_FALL_DETECTED: 'helloDevice.fallDetection',
		AI_ALERT: 'helloDevice.aiAlert',
		CANCEL_AI_ALERT: 'helloDevice.cancelAiAlert',
		TOGGLE_HEALTH_DATA: 'helloDevice.toggleHealthData',
		TOGGLE_STETHOSCOPE: 'helloDevice.toggleStethoscope',
		TOOLTYPE_PATIENTOVERVIEW: 'patientOverview',
		TOOLTYPE_STETHOSCOPE: 'stethoscopeView',
		TOOLTYPE_LIVE_EXAMINATION: 'liveExaminationView',
		START_MEASURING_IOT_DEVICES: 'helloDevice.startMeasuringIoTDevices',
		IOT_TOAST_MESSAGES: 'helloDevice.iotToastMessages',
		ON_SKELETON_DETECTIONS: 'helloDevice.skeletonDetections',
		PRIVACY_MODE_UPDATE: 'helloDevice.privacyModeUpdate',
		APP_UPDATED: 'helloDevice.apps.updated',
		ON_CALL_STATE_CHANGED: 'helloDevice.callStateChange',
		RTSP_CAMERA_EVENT: 'helloDevice.rtspCameraEvent',
		ON_AUDIO_OUTPUT_DEVICE: 'helloDevice.audioOutputDevice',
		ON_STATE_CHANGED: 'helloDevice.stateChanged',
		SEND_DOCTOR_REQUEST: 'helloDevice.sendDoctorRequest',
		ON_DOCTOR_REQUEST_RESPONSE: 'helloDevice.sendDoctorRequest.response',
		SETUP_ALEXA_ASP: 'helloDevice.setupAlexaASP',
		SETUP_ALEXA_ASP_RESPONSE: 'helloDevice.setupAlexaASP.response',
		TV_CONTROL_CHANGED: 'device.tvControl.state.changed',
		REQUEST_CURRENT_PATIENT_POSITION: 'helloDevice.requestCurrentPatientPosition',
		ON_CURRENT_PATIENT_POSITION: 'helloDevice.currentPatientPosition',
	},
	User: {
		ON_PASSWORD_CHANGED: 'user.passwordChanged',
		ON_NOTIFICATION_UPDATED: 'user.notificationsUpdated',
	},
	HealthCare: {
		REQUEST_ASSIGNED: 'healthCare.requestAssigned',
		ON_MEDIC_REQUEST_UPDATED: 'healthCare.medicAssignedRequestsUpdated',
		ON_LEVEL_REQUEST_UPDATED: 'healthCare.levelRequest.updated',
		ON_DEVICE_SETTINGS_UPDATED: 'helloDevice.setting.updated',
		ON_NEW_NOTIFICATION: 'healthCare.newNotification',
		PATIENT_CONDITION_CREATED: 'healthCare.patientConditionCreated',
		PATIENT_QUEUE_REQUEST_ADDED: 'healthcare.patientQueuedRequest.added',
		PATIENT_QUEUE_UPDATED: 'healthcare.patientQueue.updated',
		VISITS_TOGGLE_UPDATED: 'healthcare.levelVisitingHours.updated',
		MEASUREMENT_ADDED: 'healthcare.measurement.added',
		DOCTOR_PATIENT_DISSOCIATED: 'healthcare.doctorPatientDissociated',
		ON_RPM_PROGRAM_UPDATED: 'healthcare.patientRpmProgram.updated',
		ON_PATIENT_MISSED_MEDICATIONS: 'healthcare.patients.missedMedications',
		PATIENT_CONDITIONS_ADDED: 'healthcare.patientConditions.added',
		PATIENT_CONDITIONS_REMOVED: 'healthcare.patientConditions.removed',
		ON_PATIENT_CARE_EVENT_UPDATED: 'healthcare.patientCareEvent.updated',
		ALERTS_MANUAL_ADDED: 'alerts.manual.added',
		ON_TV_WIDGETS_UPDATED: 'healthcare.patientsTvWidgets.updated',
		PATIENT_OBSERVATIONS_ADDED: 'healthcare.patientObservations.added',
		PATIENT_OBSERVATIONS_REMOVED: 'healthcare.patientObservations.removed',
		ON_PATIENT_RPM_PROGRAM_REMOVED: 'healthcare.patientRpmProgram.removed',
		MONITORING_SESSION_ADDED: 'healthCare.monitoringSession.added',
		PATIENT_LANGUAGE_UPDATED: 'healthcare.patientCommunicationLanguage.updated',
		PATIENT_PROFILE_UPDATED: 'healthcare.patientProfile.updated',
	},
	Alerts: {
		ON_NEW_MEASUREMENT_ALERT: 'alerts.newMeasurementAlert',
		ON_NEW_AI_ALERT: 'alerts.newAiAlert',
		MANUAL_ACTIVITY_ADDED: 'alerts.manual.activityAdded',
		ALERT_ANALYTICS_ADDED: 'alerts.analytics.added',
		PATIENT_AI_SETTINGS_UPDATED: 'alerts.patientAiSettings.updated',
		NEW_AI_ALERT_ACTIVITY: 'alerts.newAiAlertActivity',
		AI_NOTIFICATION_TYPES_UPDATED: 'alerts.aiNotificationOptions.updated',
		ALERTS_INTERVENTION_UPDATED: 'alerts.intervention.updated',
		NEW_AI_ALERT_NOTIFICATION: 'alerts.newAiAlert.notification',
	},
	BACKGROUND: {
		BIO_BEAT_DATA: 'backgroundtask.bioBeatData',
		TASK_UPDATED: 'backgroundtask.taskStatus.updated',
	},
	LifeSignals: {
		PATCH_DATA: 'lifesignals.patch.data',
		UNASSIGN_EXTERNAL_DEVICE: 'healthcare.patientExternalDevice.unassigned',
		CPM_SUBSCRIBE: 'lifesignals.cpm.subscribe',
		CPM_UN_SUBSCRIBE: 'lifesignals.cpm.unsubscribe',
	},
	Mindray: {
		MEASUREMENTS_ADDED: 'measurements.vitals.added',
	},
	Patient: {
		PATIENT_DISCHARGE: 'patient.discharged',
		CARE_TEAM_UPDATED: 'patient.careteam.updated',
		PRACTITIONER_UPDATED: 'patient.practitioner.updated',
		MONITORING_CARE_TEAM_UPDATED: 'patient.monitoringCareTeam.updated',
	},
};

export default SocketEvents;
