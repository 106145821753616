import { getPatientAiAlertsStatistics } from 'api/alerts.js';
import { FallPreventionAlertTypes } from 'constants/ai.js';
import { AiAlertId } from 'constants/enums.js';
import FallDetection from 'icons/Monitoring/FallDetection.jsx';
import Rails from 'icons/Monitoring/Rails.jsx';
import { timezoneToUTCTimestamp } from 'infrastructure/helpers/dateHelper.js';
import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import CustomStatsWidget from 'components/DashboardCharts/PatientCentric/AI/Stats/CustomStatsWidget.jsx';
import translate from 'i18n-translations/translate.jsx';
import OutOfBed from 'icons/Monitoring/OutOfBed.jsx';
import { DashboardWidget } from 'constants/dashboard.js';

const getAlertCount = (alerts = [], typeId) => alerts.find(alert => alert.aiAlertType === typeId)?.count ?? 0;

const FallPreventionStats = ({ dateRange, selectedTimezone }) => {
	const location = useLocation();
	const [isLoading, setIsLoading] = useState(true);
	const [alertTypesCount, setAlertTypesCount] = useState([]);
	const [error, setError] = useState('');
	const { iconColor, backgroundColor } = DashboardWidget.ORANGE;

	const FallPreventionAlerts = [
		{
			id: AiAlertId.RAILS,
			icon: <Rails color={iconColor} width={30} height={30} />,
			label: translate('railAlerts'),
			total: getAlertCount(alertTypesCount, AiAlertId.RAILS),
		},
		{
			id: AiAlertId.PATIENT_GETTING_OUT_OF_BED,
			icon: <OutOfBed color={iconColor} width={30} height={30} />,
			label: translate('gettingOutOfBedAlerts'),
			total: getAlertCount(alertTypesCount, AiAlertId.PATIENT_GETTING_OUT_OF_BED),
		},
		{
			id: AiAlertId.FALL_DETECTION,
			icon: <FallDetection color={iconColor} width={30} height={30} />,
			label: translate('fallPreventionAlerts'),
			total: getAlertCount(alertTypesCount, AiAlertId.FALL_DETECTION),
		},
	];

	const fetchFallPreventionStatistics = useCallback(
		debounce(async params => {
			const response = await getPatientAiAlertsStatistics(params);
			if (response.error) {
				setError(response.error.message);
				setIsLoading(false);
				return;
			}
			setAlertTypesCount(response.aiAlertTypeCounts);
			setIsLoading(false);
			setError('');
		}, 500),
		[]
	);

	useEffect(() => {
		const { patient } = location.state;

		const params = {
			userId: patient?.healthcareUserId,
			alertTypes: FallPreventionAlertTypes,
			start: timezoneToUTCTimestamp(dateRange.from, selectedTimezone.zone),
			end: timezoneToUTCTimestamp(dateRange.to, selectedTimezone.zone),
		};

		fetchFallPreventionStatistics(params);
	}, [location, dateRange, fetchFallPreventionStatistics, selectedTimezone.zone]);

	return (
		<CustomStatsWidget
			isLoading={isLoading}
			error={error}
			title={translate('fallPreventionAlerts')}
			items={FallPreventionAlerts}
			color={backgroundColor}
		/>
	);
};
export default FallPreventionStats;
