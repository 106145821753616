const DoorIcon = props => (
	<svg width='31' height='31' viewBox='0 0 31 31' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
		<path
			d='M19.4167 6.75H22.1667C22.719 6.75 23.1667 7.19772 23.1667 7.75V25.75C23.1667 26.3023 23.6144 26.75 24.1667 26.75H25.6667'
			stroke='currentColor'
			strokeWidth='1.5'
		/>
		<path
			d='M6.91667 26.75V7.55217C6.91667 7.08347 7.2422 6.67766 7.69974 6.57598L16.9497 4.52043C17.5743 4.38165 18.1667 4.85687 18.1667 5.49662V25.75C18.1667 26.3023 17.719 26.75 17.1667 26.75H6.91667ZM6.91667 26.75H4.41667'
			stroke='currentColor'
			strokeWidth='1.5'
		/>
		<circle cx='14.4167' cy='16.75' r='1.25' fill='currentColor' />
	</svg>
);
export default DoorIcon;
