import Grid from 'components/Grid.jsx';
import translate from 'i18n-translations/translate.jsx';
import PressureInjurySummary from 'components/DashboardCharts/PatientCentric/AI/Summaries/PressureInjurySummary.jsx';
import PatientMobilitySummary from 'components/DashboardCharts/PatientCentric/AI/Summaries/PatientMobilitySummary.jsx';
import FallPreventionStats from 'components/DashboardCharts/PatientCentric/AI/Stats/FallPreventionStats.jsx';
import PressureInjuryHistory from 'components/DashboardCharts/PatientCentric/AI/History/PressureInjuryHistory.jsx';
import AlertSummaries from 'components/DashboardCharts/PatientCentric/AI/Summaries/AlertSummaries.jsx';
import AlertsHistory from 'components/DashboardCharts/PatientCentric/AI/History/AlertsHistory.jsx';
import SleepStats from 'components/DashboardCharts/PatientCentric/AI/Stats/SleepStats.jsx';

const AIOverview = ({ dateRange, selectedTimezone, isEvidenceEnabled }) => (
	<div className='ai-wrapper'>
		<label className='ai-label'>{translate('summary')}</label>
		<Grid className='grid-container'>
			<FallPreventionStats dateRange={dateRange} selectedTimezone={selectedTimezone} />
			<SleepStats dateRange={dateRange} selectedTimezone={selectedTimezone} />
			<AlertsHistory dateRange={dateRange} selectedTimezone={selectedTimezone} isEvidenceEnabled={isEvidenceEnabled} />
			<PressureInjurySummary dateRange={dateRange} selectedTimezone={selectedTimezone} />
			<PatientMobilitySummary dateRange={dateRange} selectedTimezone={selectedTimezone} />
			<AlertSummaries dateRange={dateRange} selectedTimezone={selectedTimezone} />
		</Grid>
		<PressureInjuryHistory dateRange={dateRange} selectedTimezone={selectedTimezone} isEvidenceEnabled={isEvidenceEnabled} />
	</div>
);

export default AIOverview;
